import React from 'react'
import '../index.css'
import useCustomerConfig from '../hooks/useCustomerConfig'

type Props = {
  rightSideElement: React.ReactNode
}

export const Header: React.FC<Props> = ({ rightSideElement }) => {
  const customerConfig = useCustomerConfig()
  const showHeaderLogo = customerConfig.showHeaderLogo !== false

  return (
    <header className="">
      <div className="binned-width row gap space-between">
        {showHeaderLogo && (
          <img
            className="header-logo"
            src="assets/logoSemanticPulse.png"
            alt="Logo Semantic Pulse"
          />
        )}
        {rightSideElement}
      </div>
    </header>
  )
}

export default Header
