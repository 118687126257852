import { codeSystem } from '../config'

export const apiUrl =
  process.env.REACT_APP_API_BASE_URL || 'https://api.semanticpulse.no'

export type DataSourceType =
  | 'bmjData'
  | 'felleskatalogenData'
  | 'hbibData'
  | 'hdirData'
  | 'hnorgeData'
  | 'semanticData'
  | 'varnettData'

/**
 * KnowledgeArticle type definition
 */
export interface KnowledgeArticle {
  /** The code used in the request. Either SNOMED CT, ICD-10 or ICPC-2. Always present in the response. */
  requestedCode: string
  /** Unique identifier for the data object. Always present in the response. */
  id: string
  /** Title of the knowledge article. Always present in the response. */
  title: string
  /** Short title of the knowledge article. May be empty. */
  shortTitle?: string
  /** Content of the knowledge article. */
  content: KnowledgeArticleContent
  /** Codes of the knowledge article. */
  code: {
    /** ICPC-2 codes. May be empty. */
    'ICPC-2'?: string[]
    /** ICD-10 codes. May be empty. */
    'ICD-10'?: string[]
    /** SNOMED-CT codes. May be empty. */
    'SNOMED-CT'?: string[]
  }
  /** Type of the knowledge article, e.g recommendation, patient pathway, patient information. Always present in the response. */
  infoType: string
  /** URL to the knowledge article. May be empty. */
  link?: string
  /** URL to the search page for the knowledge article. May be empty. */
  searchLink?: string
  /** The owner of, and legal responsible, for the content. Always present in the response. */
  owner: string | string[]
  /** Technical source. Always present in the response. */
  source: string
  /** Date of last update from the source. Always present in the response. */
  updatedBySource?: string // FIXME: For now, this is almost never present
  /** Date of import to the Semantic pulse. Always present in the response. */
  importDate: string
  /** Not in use? */
  professionalUpdate?: string
  /** The title of the publication the article is a part of. May be empty. */
  productTitle?: string
  /** URL to the publication the article is a part of. May be empty. */
  productUrl?: string
}

export interface KnowledgeArticleContent {
  /** Introduction to the knowledge article. May be empty. */
  intro?: string
  /** The main body text of the knowledge article. May be empty. */
  text?: string
  /** Practical guidance for recommendations. May be empty. */
  practical?: string
  /** The knowledge base for recommendations. May be empty. */
  rationale?: string
}

// API response type when searched by code. key is one of dataSources.
export type KnowledgeArticlesBySource = {
  [key in DataSourceType]: Array<KnowledgeArticle>
}

export const conditionSearchByCodeApi = async (
  code_or_infobuttonParams: string | object,
  authorizationToken: string,
  owners: String[] = [],
  quickResults: boolean,
): Promise<KnowledgeArticlesBySource> => {
  const queryParams = new URLSearchParams(
    typeof code_or_infobuttonParams === 'string'
      ? {
          codeSystem: codeSystem,
          format: 'markdown',
          code: code_or_infobuttonParams,
          quick: quickResults ? 'true' : 'false',
          owner: [...owners].join(','),
        }
      : {
          ...code_or_infobuttonParams,
          format: 'markdown',
          quick: quickResults ? 'true' : 'false',
          owner: [...owners].join(','),
        },
  )

  const url = new URL(`${apiUrl}/api/v1/articles?${queryParams.toString()}`)

  const resultsRes = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authorizationToken}`,
    },
  })

  const results: KnowledgeArticlesBySource = await resultsRes.json()
  return results
}

export const streamFromLLM = async function* (
  authorizationToken: string,
  chat: { role: string; text: string }[],
) {
  const systemMessage = {
    role: 'system',
    text: 'Husk å bekrefte indikasjon før du gir noen råd om legemiddelbytte. Snakk som en lege som henvender seg til en annen lege. Gi råd basert på indikasjon og årsak til medisinbytte oppgitt av bruker. Gi meg en liste over konkrete legemidler med begrunnelse. Bruk alltid punktlister og oppgi kort begrunnelse for hvert alternativ. Snakk alltid norsk, oppretthold en nøytral og profesjonell tone, og bruk felleskatalogen som kilde.\n\nVed oppfølgingsspørsmål fra bruker, svar på disse så godt du kan. Vært kortfattet og bruk en nøytral og profesjonell tone.',
  }
  const response = await fetch(`${apiUrl}/api/v1/chat`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authorizationToken}`,
    },
    body: JSON.stringify({ chat: [systemMessage, ...chat] }),
  })
  const reader = response.body?.getReader()
  let result = await reader?.read()
  while (!result?.done) {
    const text = new TextDecoder().decode(result?.value)
    yield text
    result = await reader?.read()
  }
}
