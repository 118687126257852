import { CustomerConfig } from './types'

const config: CustomerConfig = {
  entraClientId: '179756f6-7d73-4c08-a665-b233b1543b70',
  meta: {
    title: 'Felleskatalogen',
    description: 'Felleskatalogen: PoC for KI-assistert legemiddelbytte.',
  },
  aboutText:
    'Felleskatalogen er en norsk legemiddeldatabase. Her kan du søke etter informasjon om legemidler, finne pakningsvedlegg og preparatomtaler. Du kan også finne informasjon om interaksjoner og bivirkninger.',
  aboutLogos: [],
  theme: {
    background: '#f0f0ee',
  },
  searchRefsets: [
    { id: '88791000202108', label: 'Legemidler' },
    { id: '88161000202101', label: 'Indikasjon' },
  ],
  contentOwners: ['Felleskatalogen'],
  mainBranch: 'MAIN/SNOMEDCT-NO/CONTEIR/REFSETS',
  useChat: true,
  showHitlistLink: true,
}

export default config
