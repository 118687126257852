import configs from '../configs'
import { CustomerConfig } from '../configs/types'

export function getCustomerConfig(): {
  configName: string
} & CustomerConfig {
  const configName = process.env.REACT_APP_CUSTOMER_CONFIG || 'vestreviken'
  return {
    ...((configs as any)[configName] as CustomerConfig),
    configName: configName,
  }
}

export default function useCustomerConfig(): {
  configName: string
} & CustomerConfig {
  return getCustomerConfig()
}
