import { CustomerConfig } from './types'

const config: CustomerConfig = {
  entraClientId: 'def13860-6726-4424-9e2b-8617c7826a0b',
  meta: {
    title: 'Helsedirektoratet',
    description: 'PoC for beslutningsstøtte fra Helsedirektoratet',
  },
  aboutText:
    'Nasjonale anbefalinger, råd, pakkeforløp og pasientforløp fre Helsedirektoratet.',
  aboutLogos: [],
  theme: {
    background: '#f0f0ee',
  },
  searchRefsets: [{ id: '88161000202101', label: 'Indikasjon' }],
  contentOwners: ['Helsedirektoratet'],
  mainBranch: 'MAIN/SNOMEDCT-NO/CONTEIR/REFSETS',
  useChat: false,
}
export default config
