import React, { ReactElement, useState } from 'react'
import { Concept, SnomedConcept } from '../interfaces/Concept.interface'
import '../index.css' // Importer din stilfil her
import IconMedication from '@mui/icons-material/Medication'
import CoronavirusIcon from '@mui/icons-material/Coronavirus'
import AcUnitIcon from '@mui/icons-material/AcUnit'
import ScienceIcon from '@mui/icons-material/Science'
import BubbleChartIcon from '@mui/icons-material/BubbleChart'
import SickIcon from '@mui/icons-material/Sick'
import BiotechIcon from '@mui/icons-material/Biotech'
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy'
import PersonIcon from '@mui/icons-material/Person'
import ScaleIcon from '@mui/icons-material/Scale'
import MedicationLiquidIcon from '@mui/icons-material/MedicationLiquid'
import EventNoteIcon from '@mui/icons-material/EventNote'
import WorkIcon from '@mui/icons-material/Work'
import PublicIcon from '@mui/icons-material/Public'
import AccessibilityIcon from '@mui/icons-material/Accessibility'
import { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import { useMediaQuery } from '@mui/system'

interface IAutocompleteProps {
  onSelect(concept: Concept | undefined): void

  placeholderText: string
  selectedRefset: string

  requestCompletions(searchString: string): Promise<{ items: Concept[] }>
}

const Autocomplete = ({
  onSelect,
  placeholderText,
  requestCompletions,
}: IAutocompleteProps) => {
  const [recentlySelectedOptions, setRecentlySelectedOptions] = useState<any[]>(
    [],
  )
  // We only want to render the icons and category labels if the screen is big enough:
  const isScreenBigEnough = useMediaQuery('(min-width:600px)')

  const addToRecentlySelected = (selectedOption: any) => {
    const newOptions = [selectedOption, ...recentlySelectedOptions]
    if (newOptions.length > 10) {
      newOptions.pop()
    }
    // Remove duplicates:
    const uniqueOptions = newOptions.filter(
      (option, index, self) =>
        index ===
        self.findIndex((t) => t.value.concept.id === option.value.concept.id),
    )
    setRecentlySelectedOptions(uniqueOptions)
  }

  const uppercaseFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const loadOptions = (inputValue: string, callback: any) => {
    if (inputValue.length < 3) {
      callback([])
    } else {
      requestCompletions(inputValue).then((data) => {
        if (data && data.items) {
          const v = data.items.map((item: Concept) => ({
            label: uppercaseFirstLetter(item.concept.pt.term),
            value: item,
          }))
          callback(v)
        }
      })
    }
  }

  // showIconSmallerScreen: We want to render the icon in the dropdown, but not when the choice is selected (because it's narrower).
  const renderConcept = (
    concept: Concept,
    showIconSmallerScreen: boolean,
  ): ReactElement => (
    <div className={`row space-between align-center small-gap`}>
      <span>{uppercaseFirstLetter(concept.concept.pt.term)}</span>
      {
        <span
          style={{
            color: colorFromClassification(
              classifyFromConcept(concept.concept),
            ),
          }}
          className="row align-center small-gap"
        >
          <span className={'small-text'}>
            {classifyFromConcept(concept.concept) || 'other'}
          </span>
          {(isScreenBigEnough || showIconSmallerScreen) &&
            iconFromClassification(classifyFromConcept(concept.concept))}
        </span>
      }
    </div>
  )

  const CustomOption = ({ data, ...props }: any) => (
    <components.Option {...props}>
      {renderConcept(data.value, true)}
    </components.Option>
  )

  const CustomSingleValue = ({ data, ...props }: any) => {
    const value = data.value

    return (
      <components.SingleValue {...props}>
        {renderConcept(value, false)}
      </components.SingleValue>
    )
  }

  return (
    <AsyncSelect
      loadOptions={loadOptions}
      cacheOptions={true}
      defaultOptions={recentlySelectedOptions}
      onChange={(selectedOption: any) => {
        if (selectedOption) {
          addToRecentlySelected(selectedOption)
          onSelect(selectedOption.value)
        }
      }}
      isClearable={false}
      components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
      placeholder={placeholderText}
      loadingMessage={() => 'Laster...'}
      noOptionsMessage={({ inputValue }) =>
        inputValue.length < 3
          ? 'Skriv 3 eller flere bokstaver for å søke'
          : 'Ingen treff'
      }
    />
  )
}

export default Autocomplete

///////////////
type Classification =
  | 'disorder'
  | 'finding'
  | 'drug'
  | 'situation'
  | 'substance'
  | 'organism'
  | 'clinical drug'
  | 'medicinal product form'
  | 'medicinal product'
  | 'product'
  | 'person'
  | 'qualifier value'
  | 'observable entity'
  | 'basic dose form'
  | 'dose form'
  | 'procedure'
  | 'body structure'
  | 'occupation'
  | 'geographic location'
const classifyFromConcept = (concept: SnomedConcept): Classification | null => {
  if (concept && concept.fsn && concept.fsn.term) {
    const fsnTerm = concept.fsn.term.toLowerCase()
    const classification = classifications.find((classification) => {
      return fsnTerm.includes('(' + classification.classification + ')')
    })
    return classification?.classification as Classification
  }

  return null
}

const classifications = [
  {
    classification: 'disorder',
    icon: <SickIcon />,
    color: 'crimson',
  },
  {
    classification: 'finding',
    // icon: <BloodtypeIcon />,
    icon: <BiotechIcon />,
    color: 'red',
  },
  {
    classification: 'drug',
    icon: <IconMedication />,
    color: 'blue',
  },
  {
    classification: 'situation',
    icon: <AcUnitIcon />,
    color: 'green',
  },
  {
    classification: 'substance',
    icon: <ScienceIcon />,
    color: 'purple',
  },
  {
    classification: 'organism',
    icon: <CoronavirusIcon />,
    color: '#7a0',
  },
  {
    classification: 'clinical drug',
    icon: <IconMedication />,
    color: 'blue',
  },
  {
    classification: 'medicinal product form',
    icon: <IconMedication />,
    color: '#0aa',
  },
  {
    classification: 'medicinal product',
    icon: <LocalPharmacyIcon />,
    color: '#333',
  },
  {
    classification: 'product',
    icon: <LocalPharmacyIcon />,
    color: '#535',
  },
  {
    classification: 'person',
    icon: <PersonIcon />,
    color: '#555',
  },
  {
    classification: 'qualifier value',
    icon: <BubbleChartIcon />,
    color: '#777',
  },
  {
    classification: 'observable entity',
    icon: <ScaleIcon />,
    color: '#b77',
  },
  {
    classification: 'basic dose form',
    icon: <MedicationLiquidIcon />,
    color: '#770',
  },
  {
    classification: 'dose form',
    icon: <MedicationLiquidIcon />,
    color: '#aa0',
  },
  {
    classification: 'procedure',
    icon: <EventNoteIcon />,
    color: '#333',
  },
  {
    classification: 'body structure',
    // icon: <FaceIcon />,
    icon: <AccessibilityIcon />,
    color: '#039',
  },
  {
    classification: 'occupation',
    icon: <WorkIcon />,
    color: '#555',
  },
  {
    classification: 'geographic location',
    icon: <PublicIcon />,
    color: '#555',
  },
]

const iconFromClassification = (classification: Classification | null) => {
  const classificationData = classifications.find(
    (c) => c.classification === classification,
  )
  return classificationData?.icon || <BubbleChartIcon />
}

const colorFromClassification = (classification: Classification | null) => {
  const classificationData = classifications.find(
    (c) => c.classification === classification,
  )
  return classificationData?.color || 'gray'
}
