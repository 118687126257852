import React, { useState } from 'react'
import Markdown from 'react-markdown'

type ChatMessage = {
  role: 'user' | 'assistant' | 'system'
  text: string
  inProgress: boolean
}
type Props = {
  askLLM: (
    chat: ChatMessage[],
  ) => Promise<AsyncGenerator<string, void, unknown>>
  medicalText: string | null
}

export const ChatBox: React.FC<Props> = ({ askLLM, medicalText }) => {
  const [userText, setUserText] = useState('')
  const [conversation, setConversation] = useState([] as any[])
  const [chatRef, setChatRef] = useState<HTMLDivElement | null>(null)
  const botIsTyping =
    conversation.length > 0 && conversation[conversation.length - 1].inProgress

  const updateLastConversationMessage = (text: string, done: boolean) => {
    setConversation((conversation) => [
      ...conversation.slice(0, -1),
      { role: 'assistant', text, inProgress: !done },
    ])
  }

  const postChatMessage = async () => {
    if (botIsTyping) return

    setUserText('')
    const newConversation = [
      ...conversation,
      {
        role: 'user',
        text:
          userText +
          (medicalText
            ? '\n\nDET FØLGENDE EN EN MEDISINSK TEKST FRA FELLESKATALOGEN:\n' +
              medicalText
            : ''),
      },
    ]
    setConversation([
      ...newConversation,
      {
        role: 'assistant',
        text: '',
        inProgress: true,
      },
    ])

    let buffer = ''
    const chunkStream = await askLLM(newConversation)
    chatRef?.scrollTo(0, chatRef.scrollHeight)
    for await (let chunk of chunkStream) {
      buffer += chunk
      updateLastConversationMessage(buffer, false)
      chatRef?.scrollTo(0, chatRef.scrollHeight)
    }
    updateLastConversationMessage(buffer, true)
  }

  const renderTextWithHiddenParts = (text: string) => {
    const hideTextAfter = `DET FØLGENDE EN EN MEDISINSK TEKST FRA FELLESKATALOGEN:`
    const parts = text.split(hideTextAfter)
    const showArticleContent = false
    return (
      <>
        <Markdown>{parts[0]}</Markdown>
        {showArticleContent && parts[1] && (
          <small>
            <Markdown>{parts[1]}</Markdown>
          </small>
        )}
      </>
    )
  }

  return (
    <div className="chatbox">
      <div className="column gap">
        <div
          className="column gap overflow-scroll"
          ref={(el) => setChatRef(el)}
        >
          {conversation.map((message, i) => (
            <div
              key={i}
              className={
                `chatbox-message ${message.role} ` +
                (message.inProgress ? 'in-progress' : '')
              }
            >
              {renderTextWithHiddenParts(message.text)}
            </div>
          ))}
        </div>
        <form
          className="chatbox-input row small-gap"
          onSubmit={(e) => {
            e.preventDefault()
            postChatMessage()
          }}
        >
          <input
            placeholder="Still spørsmål til KI-assistenten her"
            type="text"
            value={userText}
            onChange={(e) => setUserText(e.target.value)}
          />
          <button type="submit" disabled={botIsTyping}>
            Send
          </button>
        </form>
      </div>
    </div>
  )
}
