import { CustomerConfig } from './types'

const config: CustomerConfig = {
  entraClientId: '1349667d-9ca0-45d9-a38c-b2a088c39fc8',
  meta: {
    title: 'Demoapp',
    description: 'Demoapp for Semantic Pulse.',
  },
  aboutText: 'Denne appen demonstrerer søkefunksjonaliteten og responsen fra vårt innholds-API som inkluderer flere anerkjente kilder. Kildene presenteres på en måte som er lett tilgjengelig for helsepersonell og et kraftig verktøy for kunnskap-og beslutningsstøtte.',
  aboutLogos: [],
  theme: {
    background: '#00ffee',
  },
  searchRefsets: [{ id: '', label: 'Symptom, diagnose eller medikament' }],
  contentOwners: [
    'BMJ',
    'Helsebiblioteket',
    'Felleskatalogen',
    'Helsedirektoratet',
    'Helsenorge',
    'NEL',
    'Mattilsynet',
    'VAR',
  ],
  mainBranch: 'MAIN/SNOMEDCT-NO/CONTEIR/REFSETS',
  useChat: false,
  useIcons: true,
  showFilterOptions: true,
}
export default config
