import React from 'react'
import { useMsal } from '@azure/msal-react'
import PersonIcon from '@mui/icons-material/Person'

const UserProfile = () => {
  const { instance, accounts } = useMsal()

  const handleLogin = (loginType: any) => {
    if (loginType === 'popup') {
      instance.loginPopup().catch((e) => {
        console.error(e)
      })
    } else {
      instance.loginRedirect().catch((e) => {
        console.error(e)
      })
    }
  }

  const handleLogout = () => {
    const answer = window.confirm('Are you sure you want to sign out?')
    if (!answer) return
    instance.logout().catch((e) => {
      console.error(e)
    })
  }

  const extractFirstName = (name: string) => {
    return name.split(' ')[0]
  }

  return (
    <div className="row">
      <div className="column  space-between binned-width align-center">
        {accounts.length > 0 ? (
          <>
            <button className="flat-button" onClick={handleLogout}>
              Logg ut
            </button>
            <PersonIcon fontSize={'large'} />
            {extractFirstName(accounts[0].name || '')}
          </>
        ) : (
          <div className="column align-center flex1 row align-center">
            {/*<h1>Logg inn for å bruke appen</h1>*/}
            <button
              className="big-button flat-button"
              onClick={() => handleLogin('popup')}
            >
              Logg inn
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default UserProfile
