import { CustomerConfig } from './types'

const config: CustomerConfig = {
  entraClientId: 'abcb1169-1f2f-41bc-9202-fd183549e4d7',
  meta: {
    title: 'Kunnskapsstøtte for Helseplattformen',
    description: 'Kunnskapsstøtte for Helseplattformen',
  },
  aboutText:
    ' **Semantic pulse**  \nSemantic Pulse er en tjeneste som gir deg tilgang til oppdatert og relevant kunnskap i din arbeidshverdag. Tjenesten er utviklet av Semantic Lab og er en del av Helseplattformen.  \n  \n**Om Semantic Lab**  \nSemantic Lab er et norsk selskap som utvikler løsninger for å forbedre helsevesenet ved hjelp av kunstig intelligens og semantisk teknologi.  \n  \n**Kontakt**  \nHar du spørsmål eller tilbakemeldinger? Ta kontakt med oss på kontakt@semantic-lab.no.',
  aboutLogos: [
    {
      src: 'assets/semanticLabLogo.png',
      alt: 'Semantic Lab',

      className: 'customer',
    },
  ],
  theme: {
    background: '#a83232',
  },
  searchRefsets: [{ id: '', label: 'Symptom, diagnose eller medikament' }],
  contentOwners: [
    'BMJ',
    'Helsebiblioteket',
    'Felleskatalogen',
    'Helsedirektoratet',
    'Helsenorge',
    'NEL',
    'VAR',
  ],
  mainBranch: 'MAIN/SNOMEDCT-NO/CONTEIR/REFSETS',
  useChat: false,
  useIcons: true,
  showFilterOptions: true,
  showHeaderLogo: false,
}
export default config
