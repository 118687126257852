import React from 'react'
import { remark } from 'remark'

const findAllH2s = (markdown: string): string[] => {
  const parsed = remark().parse(markdown)
  const allH2s = parsed.children.filter(
    (x: any) => x.type === 'heading' && x.depth === 2,
  )
  return allH2s
    .map((x: any) => {
      try {
        if (x.children && x.children[0].type === 'text') {
          return x.children[0].value
        } else if (x.children && x.children[0].children) {
          return x.children[0].children[0].value
        }
        return null
      } catch (e) {
        return null
      }
    })
    .filter((x: any) => !!x)
}

const TableOfContents = ({
  markdown,
  textToId,
  extraChapters,
}: {
  markdown: string
  textToId: (t: string) => string
  extraChapters: { title: string; id: string }[]
}) => {
  const textOfAllH2s = findAllH2s(markdown)

  const handleClick = (id: string) => (event: React.MouseEvent) => {
    event.preventDefault()
    const element = document.querySelector('#' + id)
    element?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <nav className="table-of-contents">
      <ol>
        {textOfAllH2s.map((x) => (
          <li>
            <a href={'#' + textToId(x)} onClick={handleClick(textToId(x))}>
              {x}
            </a>
          </li>
        ))}
        {extraChapters.map((x) => (
          <li>
            <a href={'#' + x.id} onClick={handleClick(x.id)}>
              {x.title}
            </a>
          </li>
        ))}
      </ol>
    </nav>
  )
}

export { TableOfContents, findAllH2s }
