import { CustomerConfig } from './types'

const config: CustomerConfig = {
  entraClientId: '8dc5fb8c-7755-4b3f-a2aa-1b14b4fe016a',
  meta: {
    title: 'Hjerneslag - Vestre Viken',
    description:
      'Kunnskapsstøtte for leger ved nevrologisk avdeling, Vestre Viken',
  },
  aboutText: `
  Denne appen er et pilotprosjekt og skal gi kunnskapsstøtte til leger ved nevrologisk avdeling, Drammen Sykehus. Målet er å gi støtte i beslutninger om pasienter med mistenkt akutt hjerneslag. Ved søk på én problemstilling kan man velge relevante artikler fra flere kilder.

  Kildene inkluderer ehåndboksdokumenter fra Vestre Viken, NevroNEL, Akuttveileder i nevrologi fra Norsk Nevrologisk Forening (NNF) og retningslinjer fra Helsedirektoratet.

  Prosjektet er et samarbeid mellom nevrologisk avdeling, Vestre Viken, Norsk Helseinformatikk (NHI) og Semantic Lab AS.

  Ved forslag til forbedringer kontakt erik@semantic-lab.no
  `,
  aboutLogos: [
    {
      src: 'assets/semanticLabLogo.png',
      alt: 'Semantic Lab',

      className: '',
    },
    { src: 'assets/logoNel.png', alt: 'NEL', className: '' },
    {
      src: 'assets/logoVv.png',
      alt: 'Vestre Viken',

      className: '',
    },
  ],
  theme: {
    background: '#f0f0ee',
  },
  searchRefsets: [
    { id: '415766571000245105', label: 'Symptom eller diagnose' },
  ],
  contentOwners: ['Helsedirektoratet', 'Vestre Viken', 'NEL'],
  mainBranch: 'MAIN/SNOMEDCT-NO/CONTEIR',
  useChat: false,
}

export default config
