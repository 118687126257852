import React from 'react'
import '../index.css'

export const Footer: React.FC<React.PropsWithChildren<{ label: string }>> = ({
  children,
  label,
}) => {
  const [isExpandend, setIsExpanded] = React.useState(false)

  return (
    <section className="footer">
      <div className="padded space-between column small-gap">
        <button
          className="footer-button clickable flat"
          onClick={() => setIsExpanded(!isExpandend)}
        >
          {isExpandend ? 'Skjul' : 'Se'} {label}
        </button>
        {isExpandend && <>{children}</>}
      </div>
    </section>
  )
}

export default Footer
