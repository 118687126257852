import './App.css'
import useCustomerConfig from './hooks/useCustomerConfig'
import Main from './pages/Main'

function App() {
  const customerConfig = useCustomerConfig()

  return (
    <div className={'App column theme-' + customerConfig.configName}>
      <Main />
    </div>
  )
}

export default App
